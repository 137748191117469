<template>
  <div class="Orderdetails_mini_item_dtls">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item-custom active">
          <span>
            <img :src="airlineLogo">
          </span>{{airlineName}}
        </li>
        <li class="breadcrumb-item-custom active" aria-current="page"> {{departureFlightNumber}} </li>
        <li class="breadcrumb-item-custom active" aria-current="page"> {{transDepartmentName}}</li>
      </ol>
    </nav>
    <div class="Orderdetails_mini_item_midel d-flex align-items-center justify-content-between">
      <div class="mini_itembox1">
        <h4>
          <strong>{{ $t("sabre.search-result.box.date-time", { date: departureDate, time: departureTime, dayName: departureDayName}) }} </strong>
          <span>{{departureCityCode}}</span>
            {{`${departureCityName} (${departureCityEnName})`}}
        </h4>
      </div>
      <div class="mini_itembox2">
        <h4>
          <strong>{{ $t("sabre.search-result.box.date-time", { date: arrivalDate, time: arrivalTime, dayName: arrivalDayName}) }} </strong>
          <span>{{arrivalCityCode}}</span>
            {{`${arrivalCityName} (${arrivalCityEnName})`}}
        </h4>
      </div>
    </div>
    <div class="baggage_area">
      <h5>{{$t("sabre.search-result.box.baggage")}}:</h5>
      <div class="d-flex align-items-center justify-content-between">
        <h6 v-if="allowCabinBaggage">
          <span>
            <img src="/assets/sabre-img/trolley_allow.png" alt="#">
          </span>{{$t("sabre.booking.order-detail-box.hand-bag-trolley")}} 40x30x20
        </h6>
        <h6 v-else>
          <span>
            <img src="/assets/sabre-img/trolley_disallow.png" alt="#">
          </span>
        </h6>
        <h6 v-if="allowCheckinBaggage">
          <span>
            <img src="/assets/sabre-img/Suitcase_allow.png" alt="#">
          </span>{{$t("sabre.booking.order-detail-box.suitcase-included")}}
        </h6>
        <h6 v-else>
          <span>
            <img src="/assets/sabre-img/Suitcase_disallow.png" alt="#">
          </span>{{$t("sabre.booking.order-detail-box.suitcase-not-included")}}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import searchResultBoxMixin from '@/sabre/fo/searchResult/searchResultBox/searchResultBoxMixin';

export default {
  name: 'order-detail-mini-item',
  mixins: [searchResultBoxMixin],
  computed: {
    ...mapGetters({
      condition: 'GET_SABRE_ORDERED_CONDITION',
    }),
    transDepartmentName() {
      const { condition } = this;
      return this.$t(`sabre.search-panel.${condition.flightDirection}`);
    },
  },
};
</script>
<style scoped>
 ol.breadcrumb {
  background: none;
 }
</style>
